<template>
	<div class="embed-responsive" :class="[ `embed-responsive-${aspectRatio}` ]">
		<div class="embed-responsive-item garages-details-map" ref="map"></div>
	</div>
</template>

<script>
import { googleMapsMixin } from "@/scripts/utils";
import { mapTheme, markerIcon } from "@/scripts/maps-theme";

export default {
	props: {
		rawLocation: String,
		aspectRatio: {
			type: String,
			default: "4by3"
		}
	},
	mixins: [googleMapsMixin],
	data() {
		return {
			location: null,
		};
	},

	mounted() {
		this.location = JSON.parse(this.rawLocation);
	},

	methods: {
		renderMap() {
			this.map = new google.maps.Map(this.$refs.map, {
				center: {
					lat: this.location.latitude,
					lng: this.location.longitude,
				},
				disableDefaultUI: true,
				styles: mapTheme,
				zoom: 13,
				gestureHandling: "none",
			});

			const marker = new google.maps.Marker({
				map: this.map,
				position: this.map.getCenter(),
				icon: markerIcon,
			});
		},
	},
};
</script>

<style scoped>
	.garages-details-map {
		pointer-events: none;
	}
</style>