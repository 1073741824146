<template>
	<form class="row row-cols-1 row-cols-sm-3" @submit.prevent="submit()">
		<div class="col">
			<label>Vælg mærke</label>
			<div class="input-group">
				<select class="custom-select" v-model="make" @change="getModels()">
					<option :value="null">Vælg mærke</option>
					<option v-for="make in makes" :value="make" :key="make.id">{{ make.name }}</option>
				</select>
			</div>
		</div>

		<div class="col mt-2 mt-sm-0">
			<label>Vælg model</label>
			<div class="input-group">
				<select class="custom-select" v-model="model" :disabled="!models.length" @change="getVehicles()">
					<option :value="null">Vælg model</option>
					<option v-for="model in models" :value="model" :key="model.name">{{ model.name }}</option>
				</select>
				<div class="input-group-append">
					<button class="btn btn-primary" type="button" @click="searchModel()" :disabled="!model || loading">
						<i class="fas fa-fw mr-2" :class="[loading ? 'fa-circle-notch fa-spin' : 'fa-search']"></i>
						Søg
					</button>
				</div>
			</div>
		</div>

		<div class="col mt-2 mt-sm-0">
			<label>Vælg variant</label>
			<div class="input-group">
				<select class="custom-select" v-model="vehicle" :disabled="!vehicles.length">
					<option :value="null">Vælg variant</option>
					<option v-for="vehicle in vehicles" :value="vehicle" :key="vehicle.name">{{ vehicle.name }}</option>
				</select>
				<div class="input-group-append">
					<button class="btn btn-primary" type="button" @click="searchVehicle()"
						:disabled="!vehicle || loading">
						<i class="fas fa-fw mr-2" :class="[loading ? 'fa-circle-notch fa-spin' : 'fa-search']"></i>
						Søg
					</button>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import axios from "axios";

export default {
	props: [
		"productSectionUrl",
	],
	data() {
		return {
			make: null,
			makes: [],
			models: [],
			model: null,
			vehicles: [],
			vehicle: null,
			loading: false,
		};
	},
	methods: {
		async getModels() {
			this.model = null;
			this.models = [];
			this.loading = true;
			if (this.make) {
				this.models = (
					await axios.get(
						`/api/products/makes/${this.make.id}/models?productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		async getVehicles() {
			this.vehicle = null;
			this.vehicles = [];
			this.loading = true;
			if (this.model) {
				this.vehicles = (
					await axios.get(
						`/api/products/makes/${this.make.id}/models/${this.model.id}/vehicles?productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		submit() {
			//window.location.href = this.model.url;
		},
		searchModel() {
			window.location.href = this.model.url;

		},
		searchVehicle() {
			window.location.href = this.vehicle.url;
		}
	},
	async created() {
		this.makes = (
			await axios.get(
				`/api/products/makes?productSectionUrl=${this.productSectionUrl}`
			)
		).data;
	},
};
</script>