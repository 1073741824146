import FrontpageTop from "@/components/Layout/FrontpageTop.vue";
import ReportError from "@/components/Forms/ReportError.vue";
import { componentRender } from "./utils";
import WAMediaBox from "wa-mediabox";
import "../../node_modules/wa-mediabox/dist/wa-mediabox.min.css";
import { CountUp } from "countup.js"

componentRender(".frontpage-top", FrontpageTop);
componentRender(".forms-report-error", ReportError);

const fontawesome = document.createElement("link");
fontawesome.rel = "stylesheet";
fontawesome.href = "https://use.fontawesome.com/releases/v5.14.0/css/all.css";
document.head.appendChild(fontawesome);

document.querySelectorAll(".carousel.lazy").forEach(carousel => {
	carousel.addEventListener("slide.bs.carousel", e => {
		const img = e.relatedTarget.querySelector("img");
		if (!img.src) {
			img.src = img.dataset.src;
		}
	})
});

document.querySelectorAll("img.lazyload").forEach(img => {
	img.src = img.dataset.src;
});

document.querySelectorAll(".countup").forEach(el => {
	const countup = new CountUp(el, parseInt(el.dataset.number), {
		startVal: Math.floor(el.dataset.number / 1000) * 1000,
		separator: ".",
		decimal: ",",
	});
	countup.start();
	el.countup = countup;
});


window.addEventListener("scroll", scrollWatch);
window.addEventListener("load", scrollWatch);


function scrollWatch() {
	if (window.scrollY > 0) {
		document.body.classList.add("scrolled");
	} else {
		document.body.classList.remove("scrolled");
	}
}

document.querySelectorAll(".popular-categories .popular-category").forEach(item => {
	item.addEventListener("click", e => {
		item.nextSibling.classList.toggle("d-none")
	})
});