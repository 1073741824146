<template>
	<ModalForm :data="contact" :api-route="'garage/contact?garageId=' + garageId" :reset="reset" id="garagesContact">
		<div class="form-group">
			<label for="name">Navn</label>
			<input id="name" class="form-control" placeholder="Navn" v-model="contact.name" required />
		</div>
		<div class="form-group">
			<label for="eamil">Email</label>
			<input type="email" id="email" class="form-control" placeholder="Email" v-model="contact.email" required />
		</div>
		<div class="form-group">
			<label for="phone">Telefonnummer</label>
			<input class="form-control" id="phone" placeholder="Telefonnummer" v-model="contact.phone" />
		</div>
		<div class="form-group">
			<label for="message">Besked</label>
			<textarea class="form-control" id="message" rows="3" placeholder="Besked" v-model="contact.message" required></textarea>
		</div>
	</ModalForm>
</template>

<script>
import ModalForm from "@/components/Forms/ModalForm.vue";

export default {
	props: ["garageId"],
	components: {
		ModalForm
	},
	data() {
		return {
			contact: {},
		};
	},
	methods: {
		reset() {
			this.contact = {};
		}
	}
};
</script>