<template>
	<div :id="this.id" class="modal fade" ref="modal">
		<div class="modal-dialog modal-dialog-centered">
			<form class="modal-content" @submit.prevent="submit()" ref="form">
				<div class="modal-header">
					<div class="h5 modal-title">{{this.title}}</div>
					<button type="button" class="close" data-dismiss="modal">&times;</button>
				</div>

				<div class="modal-body">
					<div class="alert alert-success mb-0" v-show="success">Tak for din besked</div>
					<div class="alert alert-danger" v-show="error">Der skete en fejl</div>
					<div v-show="!success">
						<slot></slot>
					</div>
				</div>

				<div class="modal-footer justify-content-between flex-sm-nowrap" v-show="!success">
					<button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="loading">Annuller</button>
					<div class="small text-muted text-sm-right w-100 order-first order-sm-0">
						Denne side er beskyttet af reCAPTCHA og Googles
						<br class="d-none d-sm-inline" />
						<a href="https://policies.google.com/privacy?hl=da" target="_blank">Privatlivspolitik</a> &amp;
						<a href="https://policies.google.com/terms?hl=da" target="_blank">Servicevilkår</a> gælder
					</div>
					<button type="submit" class="btn btn-primary" :disabled="loading">
						<span v-if="loading">
							<i class="fas fa-circle-notch fa-spin"></i>
						</span>
						<span v-else>Send</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	props: {
		data: {
			required: true,
			type: Object,
		},
		apiRoute: {
			required: true,
			type: String,
		},
		reset: {
			required: true,
			type: Function,
		},
		id: {
			required: true,
			type: String,
		},
		title: {
			type: String,
			default: "Kontakt",
		},
	},
	data() {
		return {
			loading: false,
			success: false,
			error: false,
		};
	},
	methods: {
		async submit() {
			this.loading = true;
			this.error = false;

			try {
				this.data.reCaptchaToken = await grecaptcha.execute(
					s4c.googleRecaptchaSiteKey,
					{ action: "form" }
				);
				await axios.post(`/api/forms/${this.apiRoute}`, this.data);
				this.success = true;
			} catch {
				this.error = true;
			}

			this.loading = false;
		},
	},
	mounted() {
		document
			.querySelectorAll(`[data-target='#${this.id}']`)
			.forEach((e) => {
				const modal = new BSN.Modal(e);
			});

		this.$refs.modal.addEventListener("hidden.bs.modal", () => {
			if (this.success || this.error) {
				this.success = false;
				this.error = false;
				this.reset();
			}
		});

		if (!document.getElementById(s4c.googleRecaptchaSiteKey)) {
			const recaptchaScript = document.createElement("script");
			recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${s4c.googleRecaptchaSiteKey}`;
			recaptchaScript.id = s4c.googleRecaptchaSiteKey;
			recaptchaScript.async = true;
			recaptchaScript.defer = true;
			document.head.appendChild(recaptchaScript);
		}
	},
};
</script>