<template>
	<ModalForm :data="servicebooking" :api-route="'garage/servicebooking?garageId=' + garageId" :reset="reset" id="garagesServicebooking" title="Servicebooking">
		<div class="form-row row-cols-1 row-cols-sm-2">
			<div class="col form-group">
				<label for="numberplate-servicebooking">Nummerplade</label>
				<div class="input-group">
					<input id="numberplate-servicebooking" class="form-control" placeholder="Indtast nummerplade" v-model="servicebooking.numberplate" required @keyup="getCar(true)" @keypress.enter.prevent="getCar()" />
					<div class="input-group-append">
						<button class="btn btn-outline-primary" type="button" @click="getCar()" :disabled="loading || !servicebooking.numberplate">
							<span v-if="loading">
								<i class="fas fa-circle-notch fa-spin"></i>
							</span>
							<span v-else>Hent bil</span>
						</button>
					</div>
				</div>
			</div>
			<div class="col form-group">
				<label for="mileage-servicebooking">Kilometer</label>
				<input id="mileage-servicebooking" class="form-control" placeholder="Indtast kilometer" v-model="servicebooking.mileage" required />
			</div>
		</div>
		<div class="form-group" v-show="servicebooking.car">{{servicebooking.car}}</div>

		<div class="form-row row-cols-1 row-cols-sm-3">
			<div class="col form-group">
				<label for="name-servicebooking">Navn</label>
				<input class="form-control" id="name-servicebooking" placeholder="Navn" v-model="servicebooking.name" required />
			</div>
			<div class="col form-group">
				<label for="phone-servicebooking">Telefonnummer</label>
				<input class="form-control" id="phone-servicebooking" placeholder="Telefonnummer" v-model="servicebooking.phone" required />
			</div>
			<div class="col form-group">
				<label for="email-servicebooking">Email</label>
				<input class="form-control" type="email" id="email-servicebooking" placeholder="Email" v-model="servicebooking.email" required />
			</div>
		</div>

		<div class="form-row row-cols-1 row-cols-sm-2">
			<div class="col form-group">
				<label for="date-servicebooking">Ønsket dato og tidspunkt</label>
				<input id="date-servicebooking" type="datetime-local" class="form-control" placeholder="Vælg dato og tidspunkt" v-model="servicebooking.date" required />
			</div>
			<div class="col form-group">
				<label for="alternativeDate-servicebooking">Alternativ dato og tidspunkt</label>
				<input id="alternativeDate-servicebooking" type="datetime-local" class="form-control" placeholder="Vælg dato og tidspunkt" v-model="servicebooking.alternativeDate" />
			</div>
		</div>

		<div class="form-group">
			<div class="mb-2">Hvilke services ønsker du foretaget?</div>
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="mainService-servicebooking" v-model="servicebooking.mainService">
				<label class="form-check-label" for="mainService-servicebooking">Serviceeftersyn i henhold til servicehæfte</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="bigService-servicebooking" v-model="servicebooking.bigService">
				<label class="form-check-label" for="bigService-servicebooking">Stort serviceeftersyn</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="smallService-servicebooking" v-model="servicebooking.smallService">
				<label class="form-check-label" for="smallService-servicebooking">Lille serviceeftersyn</label>
			</div>
		</div>

		<div class="form-group">
			<label for="message-servicebooking">Supplerende oplysninger</label>
			<textarea class="form-control" id="message-servicebooking" rows="3" placeholder="Supplerende oplysninger" v-model="servicebooking.message"></textarea>
		</div>
	</ModalForm>
</template>

<script>
import ModalForm from "@/components/Forms/ModalForm.vue";
import axios from "axios";

export default {
	props: ["garageId"],
	components: {
		ModalForm,
	},
	data() {
		return {
			servicebooking: {},
			loading: false,
		};
	},
	methods: {
		reset() {
			this.servicebooking = {};
		},
		async getCar(auto) {
			if (
				this.servicebooking.numberplate &&
				(auto ? this.servicebooking.numberplate.length >= 7 : true)
			) {
				this.loading = true;
				try {
					let car = (
						await axios.get(
							`https://api.seek4cars.net/v1/vehicleinfo/dk/dmr/light/${this.servicebooking.numberplate}`
						)
					).data;
					this.servicebooking.car = car.MakeModelVersion;
					this.$forceUpdate();
				} catch {
					this.servicebooking.car = null;
				}
				this.loading = false;
			}
		},
	},
};
</script>