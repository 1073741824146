<template>
	<div class="filter-list">
		<div class="filter-list-items">
			<div class="filter-list-item" v-for="item in filtered" :key="item.name">
				<div :class="`custom-control custom-${single ? 'radio' : 'checkbox'}`">
					<input
						:type="single ? 'radio' : 'checkbox'"
						:id="`${id}-${item.name}`"
						:name="id"
						class="custom-control-input"
						v-model="$root.fields[id]"
						:value="item.id"
					/>
					<label class="custom-control-label" :for="`${id}-${item.name}`">{{item.name}}</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["data", "id", "query", "single"],
	computed: {
		filtered() {
			return this.data.filter(x => x.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
		},
	},
	methods: {
		selectFirst() {
			if (this.filtered.length > 0 && !this.$root.fields[this.id].includes(this.filtered[0].id)) {
				this.$root.fields[this.id].push(this.filtered[0].id);
				return true;
			}
			return false;
		}
	},
}
</script>