<template>
	<div class="share" @click="share()">
		<span class="label">
			Del
			<i class="fab fa-facebook-square fa-fw" key="on"></i>
		</span>
	</div>
</template>

<script>
export default {
	props: ["id", "type"],
	methods: {
		share() {
			if(gtag) {
				gtag("event", "share", {
					method: "Facebook",
					item_id: this.id,
					content_type: this.type,
				});
			}
			window.open(
				"//www.facebook.com/sharer.php?u=" +
					encodeURIComponent(location.href) +
					"&t=" +
					encodeURI(document.title),
				"_blank",
				"menubar=0,toolbar=0,status=0,location=0,scrollbars=0,width=664,height=260"
			);
		},
	},
};
</script>