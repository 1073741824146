import Favorite from "@/components/Features/Favorite.vue"
import Compare from "@/components/Features/Compare.vue"
import Share from "@/components/Features/Share.vue"
import { componentRender } from "./utils";

function render() {
	document.querySelectorAll(".favorite").forEach(el => el.remove());
	document.querySelectorAll(".compare").forEach(el => el.remove());
	document.querySelectorAll(".share").forEach(el => componentRender(el, Share, { ...el.closest("[data-id]").dataset}));
}

render();

const observer = new MutationObserver((mutations) => {
	render();
});

const list = document.body.querySelector(".list");
if(list) {
	observer.observe(document.body.querySelector(".list"), { childList: true });
}
