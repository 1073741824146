<template>
	<div class="cars-search">
		<form :action="action">
			<div :class="`${slim ? 'form-row' : 'row'}`">
				<div :class="colClasses">
					<div class="form-group">
						<label>Mærke</label>
						<select class="custom-select" v-model="activeMake" @change="getModels()">
							<option :value="null">Alle mærker</option>
							<optgroup label="Mest populære">
								<option :value="item" v-for="item in popularMakes" :key="item.name">{{item.name}}</option>
							</optgroup>
							<optgroup label="Alle mærker">
								<option :value="item" v-for="item in makes" :key="item.name">{{item.name}}</option>
							</optgroup>
						</select>
					</div>
				</div>
				<div :class="colClasses">
					<div class="form-group">
						<label>Model</label>
						<select class="custom-select" v-model="activeModel" :disabled="models.length === 0">
							<option :value="null">Alle modeller</option>
							<option :value="item" v-for="item in models" :key="item.name">{{item.name}}</option>
						</select>
					</div>
				</div>
				<div :class="colClasses" v-if="!slim">
					<div class="form-group">
						<label>Modelår</label>
						<FilterRange id="year" :data="years" />
					</div>
				</div>
				<div :class="colClasses" v-if="!slim">
					<div class="form-group">
						<label>Pris</label>
						<FilterRange id="price" :data="prices" />
					</div>
				</div>
				<div :class="colClasses" v-if="!slim">
					<div class="form-group">
						<label>Kilometer</label>
						<FilterRange id="mileage" :data="mileages" />
					</div>
				</div>
				<div :class="colClasses" v-if="!slim">
					<div class="form-group">
						<label>Brændstof</label>
						<select class="custom-select" name="fuelTypes">
							<option :value="null">Alle</option>
							<option :value="item.id" v-for="item in fuelTypes" :key="item.name">{{item.name}}</option>
						</select>
					</div>
				</div>
				<div :class="colClasses" v-if="!slim">
					<div class="form-group">
						<label>Biltype</label>
						<select class="custom-select" name="bodyTypes">
							<option :value="null">Alle</option>
							<option :value="item.id" v-for="item in bodyTypes" :key="item.name">{{item.name}}</option>
						</select>
					</div>
				</div>
				<div class="col align-self-end">
					<div class="form-group">
						<button type="submit" class="btn btn-primary btn-block">
							<i class="fas fa-search mr-2"></i> Søg
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import { prices, mileages, years } from "@/scripts/cars";
import FilterRange from "@/components/Filter/FilterRange.vue";

export default {
	props: {
		slim: Boolean,
	},
	components: {
		FilterRange,
	},
	data() {
		return {
			activeMake: null,
			activeModel: null,
			makes: [],
			models: [],
			years,
			prices,
			mileages,
			fuelTypes: [],
			bodyTypes: [],
			popularMakes: [],
		};
	},
	computed: {
		action() {
			if (this.activeModel) {
				return this.activeModel.url;
			} else if (this.activeMake) {
				return this.activeMake.url;
			} else {
				return "/biler-til-salg";
			}
		},
		colClasses() {
			return this.slim
				? "col-sm-5 col-md-6 col-lg-5"
				: "col-md-3 col-sm-6";
		}
	},
	methods: {
		async getModels() {
			this.activeModel = null;
			if (!this.activeMake) {
				this.models = [];
			} else {
				this.models = (
					await axios.get(
						`/api/cars/makes/${this.activeMake.id}/models`
					)
				).data;
			}
		},
	},
	async mounted() {
		this.makes = (await axios.get(`/api/cars/makes`)).data;
		this.popularMakes = (await axios.get("/api/cars/popularmakes")).data;
		if (!this.slim) {
			this.fuelTypes = (await axios.get("/api/cars/fueltypes")).data;
			this.bodyTypes = (await axios.get("/api/cars/bodytypes")).data;
		}
	},
};
</script>

<style>
</style>