import { componentRender } from "./utils";
import GaragesMap from "@/components/garages/Map.vue";
import GaragesDetailsMap from "@/components/garages/DetailsMap.vue";
import GaragesContact from "@/components/garages/Contact.vue";
import GaragesMissingForm from "@/components/garages/MissingForm.vue";
import GaragesServicebooking from "@/components/garages/Servicebooking.vue";

componentRender(".garages-map", GaragesMap);
componentRender(".garages-details-map", GaragesDetailsMap);
componentRender(".garages-contact", GaragesContact);
componentRender(".garages-missing-form", GaragesMissingForm);
componentRender(".garages-servicebooking", GaragesServicebooking);