<template>
	<ModalForm :data="contact" api-route="report/error" :reset="reset" id="reportError" title="Rapportér fejl">
		<div class="form-group">
			<label for="nameError">Navn</label>
			<input id="nameError" class="form-control" placeholder="Navn" v-model="contact.name" required />
		</div>
		<div class="form-group">
			<label for="emailError">Email</label>
			<input type="email" id="emailError" class="form-control" placeholder="Email" v-model="contact.email" required />
		</div>
		<div class="form-group">
			<label for="phoneError">Telefonnummer</label>
			<input class="form-control" id="phoneError" placeholder="Telefonnummer" v-model="contact.phone" />
		</div>
		<div class="form-group">
			<label for="messageError">Besked</label>
			<textarea class="form-control" id="messageError" rows="3" placeholder="Besked" v-model="contact.message" required></textarea>
		</div>
	</ModalForm>
</template>

<script>
import ModalForm from "@/components/Forms/ModalForm.vue";

export default {
	components: {
		ModalForm
	},
	data() {
		return {
			contact: {},
		};
	},
	methods: {
		reset() {
			this.contact = {};
		}
	}
};
</script>