<template>
	<div class="filter-group-list">
		<div class="filter-list-items">
			<div v-for="(items, key) in groups" :key="key" class="filter-group">
				<div class="filter-group-name">{{key}}</div>
				<FilterList :data="items" :query="query" :id="id" ref="test" />
			</div>
		</div>
	</div>
</template>

<script>
import FilterList from "./FilterList.vue"

export default {
	props: ["data", "id", "query"],
	components: {
		FilterList,
	},
	computed: {
		groups() {
			return this.data;
		},
	},
	methods: {
		selectFirst() {
			return this.$refs.test.find(x => x.filtered.length > 0).selectFirst();
		}
	},
}
</script>