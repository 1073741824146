<template>
	<div :class="`filter-item ${active ? 'open' : ''}`" ref="item">
		<button
			type="button"
			:class="`btn filter-item-trigger ${info ? 'active' : ''}`"
			:disabled="disabled"
			@click.stop="toggle()"
		>
			{{label}}
			<span v-show="info" class="filter-item-info">
				<span>{{info}}</span>
			</span>
		</button>
		<div class="filter-item-content" v-show="active">
			<div class="filter-item-query" v-show="searchable">
				<input
					placeholder="Søg i filter"
					type="search"
					class="form-control"
					v-model="query"
					ref="query"
					@keyup.enter="searchSelect"
				/>
			</div>

			<div :class="`${searchable ? 'filter-item-scroll' : ''}`">
				<component
					:is="'Filter' + type"
					:data="data"
					:query="query"
					:single="single"
					:id="id"
					ref="type"
				/>
			</div>

			<div class="filter-item-buttons">
				<button type="button" class="btn btn-link text-danger" @click="reset()">Nulstil</button>
				<button type="button" class="btn btn-link text-primary" @click="close()">Filtrér</button>
			</div>
		</div>
		<portal to="filter-actives">
			<template v-if="type === 'Range'">
				<button class="btn btn-sm btn-primary mr-2" v-if="value.min || value.max" @click="reset()">
					{{label}}: {{info}}
					<i class="fa fa-times fa-fw"></i>
				</button>
			</template>
			<template v-else-if="type === 'Text' && value">
				<button class="btn btn-sm btn-primary mr-2" @click="reset()">
					{{label}}: {{info}}
					<i class="fa fa-times fa-fw"></i>
				</button>
			</template>
			<template v-else-if="data">
				<template v-if="single && !Array.isArray(value)">
					<button class="btn btn-sm btn-primary mr-2" @click="reset()">
						<span v-if="type==='List'">{{data.find(x => x.id == value).name}}</span>
						<span v-else>{{Object.values(data).flat().find(x => x.id == value).name}}</span>
						<i class="fa fa-times fa-fw"></i>
					</button>
				</template>
				<template v-else>
					<button
						class="btn btn-sm btn-primary mr-2"
						v-for="(id, index) in value"
						:key="`${id}+${index}`"
						@click="removeAt(index)"
					>
						<span v-if="type==='List'">{{data.find(x => x.id == id).name}}</span>
						<span
							v-else-if="Object.values(data).flat().find(x => x.id == id)"
						>{{Object.values(data).flat().find(x => x.id == id).name}}</span>
						<i class="fa fa-times fa-fw"></i>
					</button>
				</template>
			</template>
		</portal>
	</div>
</template>

<script>
import FilterList from "./FilterList.vue";
import FilterGroupList from "./FilterGroupList.vue";
import FilterRange from "./FilterRange.vue";
import FilterText from "./FilterText.vue";

export default {
	props: ["id", "label", "searchable", "type", "data", "single"],
	components: {
		FilterList,
		FilterGroupList,
		FilterRange,
		FilterText,
	},
	data() {
		return {
			query: "",
		};
	},
	computed: {
		active() {
			return this.$parent.$data.view === this.id;
		},
		disabled() {
			return this.data && Object.keys(this.data).length === 0;
		},
		value() {
			return this.$root.fields[this.id];
		},
		info() {
			switch (this.type) {
				case "Text":
					return this.value ? this.value : "";
				case "Range":
					let info = "";
					if (this.value.min) {
						info += `${
							this.data.format
								? this.value.min.toLocaleString("da-DK")
								: this.value.min
						} ${this.data.unit ? this.data.unit : ""}`;
						if (!this.value.max) {
							info = "Mindst " + info;
						}
					}
					if (this.value.max) {
						info += this.value.min ? " - " : "Op til ";
						info += `${
							this.data.format
								? this.value.max.toLocaleString("da-DK")
								: this.value.max
						} ${this.data.unit ? this.data.unit : ""}`;
					}
					return info;
					break;
				case "GroupList":
					if (this.value.length === 0) {
						return "";
					} else if (this.value.length === 1 && this.data) {
						const selected = Object.values(this.data)
							.flat()
							.find((x) => x.id == this.value[0]);
						return selected ? selected.name : this.value.length;
					} else {
						return this.value.length;
					}
					break;
				default:
					if (this.value.length === 0) {
						return "";
					} else if (this.value.length === 1 && this.data) {
						return this.data.find((x) => x.id == this.value[0])
							.name;
					} else {
						return this.value.length;
					}
					break;
			}
		},
	},
	methods: {
		close() {
			this.$parent.$data.view = null;
		},

		open() {
			this.query = "";
			this.$parent.$data.view = this.id;

			if (this.$refs.query && !this.$parent.mobileShow) {
				this.$nextTick(() => {
					this.$refs.query.focus();
				});
			}

			if (this.type === "Text") {
				this.$nextTick(() => {
					this.$refs.type.$refs.input.focus();
				});
			}
		},

		toggle() {
			this.active ? this.close() : this.open();
		},

		documentClick(e) {
			if (!this.$refs.item.contains(e.target)) {
				this.close();
			}
		},

		reset() {
			switch (this.type) {
				case "Range":
					this.$set(this.$root.fields, this.id, {
						min: null,
						max: null,
					});
					break;
				case "Text":
					this.$set(this.$root.fields, this.id, null);
					break;
				default:
					this.$set(this.$root.fields, this.id, []);
					break;
			}
		},

		searchSelect() {
			if (this.$refs.type.selectFirst && this.query.length > 0) {
				if (this.$refs.type.selectFirst()) {
					this.close();
				}
			}
		},

		removeAt(index) {
			this.$set(this.$root.fields, this.id, [
				...this.$root.fields[this.id].slice(0, index),
				...this.$root.fields[this.id].slice(index + 1),
			]);
		},
	},
	watch: {
		active(val) {
			if (val) {
				document.addEventListener("click", this.documentClick);
			} else {
				document.removeEventListener("click", this.documentClick);
			}
		},
	},
	beforeDestroy() {
		document.removeEventListener("click", this.documentClick);
	},
};
</script>