<template>
	<div class="products-category-groups">
		<div class="row">
			<template v-for="group in categoryGroups">
				<div class="col-12 col-sm-6 mb-4" v-show="!group.hidden" :key="group.name">
					<div class="box group" @click="showSubgroups(group)">
						<div class="h3 text-center">{{ group.name }}</div>
						<span v-html="group.svg"></span>
					</div>
				</div>

				<template v-for="subgroup in group.subgroups">
					<div
						 class="col-12 col-sm-6 mb-4"
						 :key="subgroup.name"
						 v-show="!subgroup.hidden && subgroup.hidden != null">
						<div class="box subgroup h-100" @click="showCategories(subgroup, group)">
							<div class="h4 text-center">{{ subgroup.name }}</div>
							<span v-html="subgroup.svg"></span>
						</div>
					</div>

					<div
						 :key="subgroup.name + '-categories'"
						 class="col-12"
						 v-show="subgroup.showCategories">
						<div class="box">
							<div class="row">
								<div
									 class="col order-md-last text-center mb-3"
									 v-html="subgroup.svg"></div>
								<div class="col-md-8">
									<div
										 v-for="category in subgroup.categories"
										 :key="category.id"
										 class="mb-3">
										<a :href="category.url" class="font-weight-bold">
											{{ category.name }}
										</a>

										<div class="form-row" v-if="category.children">
											<div
												 class="col-lg-4 col-sm-6 mb-1"
												 v-for="childCategory in category.children"
												 :key="childCategory.id">
												<a :href="childCategory.url">
													{{ childCategory.name }}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	props: {
		categoryGroupsRaw: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			categoryGroups: [],
			prevCategoryGroupsStates: [],
			// prevGroup: null,
		};
	},
	mounted() {
		this.categoryGroups = JSON.parse(this.categoryGroupsRaw);
		this.categoryGroups.forEach(async (group) => {
			this.$set(group, "svg", await this.loadSvg(group.image));
			group.subgroups.forEach(async (subgroup) => {
				this.$set(subgroup, "svg", await this.loadSvg(subgroup.image));
			});
		});

		window.onpopstate = (e) => {
			if (this.prevCategoryGroupsStates.length) {
				this.$set(
					this,
					"categoryGroups",
					this.prevCategoryGroupsStates.pop()
				);
			}
		};
	},
	computed: {},
	methods: {
		async loadSvg(image) {
			if (image && image.length) {
				return (
					await axios.get(`/images/products-category-groups/${image}`)
				).data;
			}
		},
		showSubgroups(group) {
			this.pushState();

			group.subgroups.forEach((c) => this.$set(c, "hidden", false));
			this.categoryGroups.forEach((c) => this.$set(c, "hidden", true));
		},
		showCategoryGroups() {
			this.pushState();

			this.categoryGroups.forEach((c) => this.$set(c, "hidden", false));
		},
		showCategories(subgroup, group) {
			this.pushState();

			this.$set(subgroup, "showCategories", true);
			group.subgroups.forEach((c) => this.$set(c, "hidden", true));
		},
		pushState() {
			history.pushState({}, "");
			this.prevCategoryGroupsStates.push(
				JSON.parse(JSON.stringify(this.categoryGroups))
			);
		},
	},
};
</script>

<style lang="scss">
.products-category-groups {
	.group,
	.subgroup {
		cursor: pointer;
	}

	$primary: #f47721;
	$gray-600: #6c757d;

	svg {
		#Fill * {
			fill: $gray-600;
			fill-opacity: 0.2;
			opacity: 1;
		}

		#Rød_over * {
			fill: $primary;
			fill-opacity: 1;
			opacity: 1;
		}

		#Outline * {
			fill: $gray-600;
			opacity: 1;
			fill-opacity: 1;
			stroke: none;
		}

		#Rød_under * {
			fill: $primary;
			fill-opacity: 1;
			opacity: 1;
		}
	}
}
</style>