
export const mapTheme = [
	{
		"featureType": "administrative",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#444444"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [
			{
				"color": "#f2f2f2"
			}
		]
	},
	{
		"featureType": "landscape.natural",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"color": "#e6e6e6"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "all",
		"stylers": [
			{
				"saturation": -100
			},
			{
				"lightness": 45
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"hue": "#ff0000"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#fd9836"
			},
			{
				"saturation": "85"
			},
			{
				"lightness": "31"
			},
			{
				"gamma": "1.24"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"hue": "#ff0000"
			},
			{
				"saturation": "1"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit.station",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			},
			{
				"hue": "#ff0000"
			},
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "transit.station.airport",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "transit.station.bus",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "transit.station.rail",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			},
			{
				"hue": "#ff7e00"
			},
			{
				"saturation": "-100"
			},
			{
				"lightness": "19"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "all",
		"stylers": [
			{
				"color": "#d7d7d7"
			},
			{
				"visibility": "on"
			}
		]
	}
];

// export const markerIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAANlBMVEUAAAD0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH0dyH////7zKv1gC9qnGeLAAAADnRSTlMAEO+PIIDPv3BAn2DfryKo9PMAAACJSURBVCjPdZFbDsMgDAQBO5D0sST3v2wpqqJdV8xXpNHEGNKNWwOaeYpkww/LwVTcVHUGwtg4BI/RdfZ+XjGbk4YZDoNGCl/6ZH6uVSHV9IdvUrse48kbgymy84PVlhgPkWQcKblwpGwULbLXn5kZ76SZTNKMJykeX1FfrSZCL3lfqAM4FspVfQB8oQ3GO2AwpwAAAABJRU5ErkJggg==";


export { default as markerIcon } from "@/images/maps-theme/marker.png";
import m1 from "@/images/maps-theme/m1.png";
import m2 from "@/images/maps-theme/m2.png";
import m3 from "@/images/maps-theme/m3.png";

export const clusterTheme = [
	{
		url: m1,
		width: 53,
		height: 52,
		textColor: "#fff",
		anchorText: [21, 0],
	},
	{
		url: m2,
		width: 56,
		height: 55,
		textColor: "#fff",
		anchorText: [23, 0],
	},
	{
		url: m3,
		width: 66,
		height: 65,
		textColor: "#fff",
		anchorText: [28, 0],
	},
]