<template>
	<div class="compare" @click.prevent="toggle()">
		<span class="label">Sammenlign</span>
		<transition name="bounce" mode="out-in">
			<i v-if="compare" class="fas fa-clone fa-fw" key="on"></i>
			<i v-if="!compare" class="far fa-clone fa-fw" key="off"></i>
		</transition>
	</div>
</template>

<script>
export default {
	props: ["id", "type"],
	data() {
		return {
			key: `compare.${this.type}:${this.id}`,
			get compare() {
				return localStorage.getItem(this.key) || false;
			},
			set compare(value) {
				if (value) {
					localStorage.setItem(this.key, true)
				} else {
					localStorage.removeItem(this.key);
				}
			},
		}
	},
	methods: {
		toggle() {
			if (localStorage.getItem(this.key)) {
				this.compare = false;
			} else {
				this.compare = true;
			}
		}
	},
}
</script>