<template>
	<input type="search" class="form-control" ref="input" v-model="value" :placeholder="label" />
</template>

<script>
import debounce from "@/scripts/utils";

export default {
	props: ["id", "label"],
	data() {
		return {
			timeout: null,
		};
	},
	computed: {
		value: {
			get() {
				return this.$root.fields[this.id];
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.$root.fields[this.id] = val;
				}, 500);
			},
		},
	},
};
</script>

<style>
</style>