<template>
	<div class="garages-map map" ref="map"></div>
</template>

<script>
import MarkerClusterer from "@google/markerclustererplus";
import { googleMapsMixin } from "@/scripts/utils";
import { mapTheme, markerIcon, clusterTheme } from "@/scripts/maps-theme";

export default {
	props: ["rawResult"],
	mixins: [googleMapsMixin],
	methods: {
		renderMap() {
			const result = JSON.parse(this.rawResult);
			this.map = new google.maps.Map(this.$refs.map, {
				center: {
					lat: result.foundLocation.latitude,
					lng: result.foundLocation.longitude,
				},
				disableDefaultUI: true,
				zoomControl: true,
				styles: mapTheme,
			});

			const bounds = new google.maps.LatLngBounds();
			const markers = [];
			for (const garage of result.items) {
				const marker = new google.maps.Marker({
					map: this.map,
					position: {
						lat: garage.location.latitude,
						lng: garage.location.longitude,
					},
					title: garage.name,
					icon: markerIcon,
				});

				marker.addListener("click", () => (location = garage.url));

				bounds.extend(marker.position);
				markers.push(marker);
			}

			this.map.setCenter(bounds.getCenter());
			this.map.fitBounds(bounds);

			const cluster = new MarkerClusterer(this.map, markers, {
				averageCenter: true,
				gridSize: 40,
				styles: clusterTheme,
				maxZoom: 14,
			});
		},
	},
};
</script>

<style scoped>
.map {
	height: 50vh;
}
</style>