<template>
	<ModalForm :data="contact" api-route="garage/missing" :reset="reset" id="garagesMissingForm" title="Manglende firma">
		<div class="font-weight-bold mb-2">Firmaoplysninger</div>

		<div class="form-row row-cols-1 row-cols-sm-2">
			<div class="col col-sm-12 form-group">
				<label for="vat-garagesMissingForm">CVR</label>
				<div class="input-group">
					<input id="vat-garagesMissingForm" class="form-control" placeholder="CVR" v-model="contact.company.vat" @keypress.enter.prevent="getCompany()" />
					<div class="input-group-append">
						<button class="btn btn-outline-primary" type="button" @click="getCompany()" :disabled="loading || !contact.company.vat">
							<span v-if="loading">
								<i class="fas fa-circle-notch fa-spin"></i>
							</span>
							<span v-else>Hent firma</span>
						</button>
					</div>
				</div>
			</div>

			<div class="col form-group">
				<label for="companyName-garagesMissingForm">Firmanavn</label>
				<input id="companyName-garagesMissingForm" class="form-control" placeholder="Firmanavn" v-model="contact.company.name" required />
			</div>
			<div class="col form-group">
				<label for="companyPhone-garagesMissingForm">Telefonnummer</label>
				<input class="form-control" id="companyPhone-garagesMissingForm" placeholder="Telefonnummer" v-model="contact.company.phone" required />
			</div>

			<div class="col form-group">
				<label for="companyAddress-garagesMissingForm">Adresse</label>
				<input class="form-control" id="companyAddress-garagesMissingForm" placeholder="Adresse" v-model="contact.company.address" required />
			</div>
			
			<div class="col form-group">
				<div class="form-row">
					<div class="col-4">
						<label for="companyZipCode-garagesMissingForm">Postnr.</label>
						<input class="form-control" id="companyZipCode-garagesMissingForm" placeholder="Postnr." v-model="contact.company.zipCode" required />
					</div>
					<div class="col">
						<label for="companyCity-garagesMissingForm">By</label>
						<input class="form-control" id="companyCity-garagesMissingForm" placeholder="By" v-model="contact.company.city" required />
					</div>
				</div>
			</div>

			<div class="col form-group">
				<label for="companyEmail-garagesMissingForm">Email</label>
				<input type="email" id="companyEmail-garagesMissingForm" class="form-control" placeholder="Email" v-model="contact.company.email" required />
			</div>

			<div class="col form-group">
				<label for="companyWebsite-garagesMissingForm">Hjemmeside</label>
				<input id="companyWebsite-garagesMissingForm" class="form-control" placeholder="Hjemmeside" v-model="contact.company.website" />
			</div>
		</div>

		<div class="form-group">
			<label for="message-garagesMissingForm">Besked <small>(Andre oplysninger eks. åbningstider og facebook side)</small></label>
			<textarea class="form-control" id="message-garagesMissingForm" rows="3" placeholder="Besked" v-model="contact.message"></textarea>
		</div>

		<div class="font-weight-bold mb-2 mt-3">Dine oplysninger</div>

		<div class="form-row row-cols-1 row-cols-sm-2">
			<div class="col-sm-12 form-group">
				<label for="name-garagesMissingForm">Navn</label>
				<input id="name-garagesMissingForm" class="form-control" placeholder="Navn" v-model="contact.name" required />
			</div>

			<div class="col form-group">
				<label for="email-garagesMissingForm">Email</label>
				<input type="email" id="email-garagesMissingForm" class="form-control" placeholder="Email" v-model="contact.email" required />
			</div>

			<div class="col form-group">
				<label for="phone-garagesMissingForm">Telefonnummer</label>
				<input class="form-control" id="phone-garagesMissingForm" placeholder="Telefonnummer" v-model="contact.phone" />
			</div>
		</div>
	</ModalForm>
</template>

<script>
import axios from "axios";
import ModalForm from "@/components/Forms/ModalForm.vue";

export default {
	components: {
		ModalForm
	},
	data() {
		return {
			contact: {
				company: {},
			},
			loading: false,
		};
	},
	methods: {
		reset() {
			this.contact = {
				company: {},
			};
		},
		async getCompany() {
			if (this.contact.company.vat) {
				this.loading = true;
				try {
					let company = (await axios.get(`https://cvrapi.dk/api?country=dk&vat=${this.contact.company.vat}`)).data;
					this.contact.company.name = company.name;
					this.contact.company.address = company.address;
					this.contact.company.zipCode = company.zipcode;
					this.contact.company.city = company.city;
					this.contact.company.phone = company.phone;
					this.contact.company.email = company.email;
					this.$forceUpdate();
				} catch {

				}
				this.loading = false;
			}
		}

	}
};
</script>