<template>
	<ModalForm :data="contact" :api-route="'car/contact?carId=' + carId" :reset="reset" id="carsContact">
		<div class="form-row">
			<div class="col-sm-5">
				<div class="form-group">
					<label for="name">Navn</label>
					<input id="name" class="form-control" placeholder="Navn" v-model="contact.name" required />
				</div>
				<div class="form-group">
					<label for="eamil">Email</label>
					<input type="email" id="email" class="form-control" placeholder="Email" v-model="contact.email" required />
				</div>
				<div class="form-group">
					<label for="phone">Telefonnummer</label>
					<input class="form-control" id="phone" placeholder="Telefonnummer" v-model="contact.phone" :required="contact.callMe" />
				</div>
			</div>
			<div class="col">
				<div class="form-group">
					<label for="message">Besked</label>
					<textarea class="form-control" id="message" rows="3" placeholder="Besked" v-model="contact.message" :required="!bookTestDrive && !contact.callMe && !exchangeCar"></textarea>
				</div>
				<div class="form-group">
					<div class="custom-control custom-checkbox">
						<input type="checkbox" id="bookTestDrive" class="custom-control-input" v-model="bookTestDrive" @change="toggleBookTestDrive()" />
						<label class="custom-control-label" for="bookTestDrive">Bestil en prøvetur</label>
					</div>
					<div class="form-group mt-2" v-if="bookTestDrive">
						<label>Hvilket tidrum passer dig bedst?</label>
						<div class="d-flex">
							<select class="form-control mr-2" v-model="contact.testDriveDate" required>
								<option :value="null">Vælg dato</option>
								<option :value="date" v-for="(date, index) in dates" :key="index">{{dateName(date)}}</option>
							</select>
							<select class="form-control" v-model="contact.testDriveTime" required>
								<option :value="null">Vælg tid</option>
								<option :value="time" v-for="time in times" :key="time">kl. {{time}}</option>
							</select>
						</div>
						<small class="text-muted font-italic">
							<b>Bemærk:</b> Dette er kun en forespørgsel
						</small>
					</div>
				</div>
				<div class="form-group">
					<div class="custom-control custom-checkbox">
						<input type="checkbox" id="exchangeCar" class="custom-control-input" v-model="exchangeCar" @change="toggleExchangeCar()" />
						<label class="custom-control-label" for="exchangeCar">Lad min nuværende bil indgå handlen</label>
					</div>
					<div v-if="exchangeCar">
						<div class="d-flex mt-2">
							<input class="form-control mr-2" placeholder="Nummerplade" v-model="contact.numberplate" @change="getVehicle()" required />
							<input class="form-control" placeholder="Kilometer" v-model="contact.mileage" required />
						</div>
						<small v-if="vehicle" class="text-muted font-italic">{{vehicle.makeModelVersion}}</small>
					</div>
				</div>
				<div class="custom-control custom-checkbox form-group">
					<input type="checkbox" id="callMe" class="custom-control-input" v-model="contact.callMe" />
					<label class="custom-control-label" for="callMe">Ring mig op</label>
				</div>
			</div>
		</div>
	</ModalForm>
</template>

<script>
import axios from "axios";
import ModalForm from "@/components/Forms/ModalForm.vue";

export default {
	props: ["carId"],
	data() {
		return {
			contact: {
				testDriveDate: null,
				testDriveTime: null,
			},
			bookTestDrive: false,
			exchangeCar: false,
			vehicle: null,
		};
	},
	components: {
		ModalForm
	},
	computed: {
		dates() {
			let dates = [];
			for (let i = 0; i < 14; i++) {
				var date = new Date();
				date.setDate(date.getDate() + i);
				dates.push(date);
			}
			return dates;
		},
		times() {
			let times = [];
			for (let i = 9; i <= 16; i++) {
				times.push(`${i} - ${i + 1}`);
			}
			return times;
		}
	},
	methods: {
		dateName(date) {
			return date.toLocaleDateString("da-DK", { weekday: "long", month: "long", day: "numeric" });
		},
		async getVehicle() {
			this.vehicle = (await axios.get(`/api/forms/numberplate/${this.contact.numberplate}`)).data;
		},
		toggleExchangeCar() {
			if (!this.exchangeCar) {
				this.contact.numberplate = null;
				this.contact.mileage = null;
				this.vehicle = null;
			}
		},
		toggleBookTestDrive() {
			if (!this.bookTestDrive) {
				this.contact.testDriveDate = null;
				this.contact.testDriveTime = null;
			}
		},
		reset() {
			Object.assign(this.$data, this.$options.data());
		}
	},
};
</script>