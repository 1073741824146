<template>
	<div class="frontpage-top">
		<div class="row">
			<div class="col-lg-6 mb-4 mb-lg-0">
				<div class="box h-100 d-flex flex-column">
					<div class="h2 text-center">Biler til salg</div>
					<div class="my-4">
						<CarsSearch slim />
					</div>
					<a href="/biler" class="btn btn-block btn-link">
						Flere søgemuligheder
						<i class="fa fa-angle-right fa-fw"></i>
					</a>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="box h-100 d-flex flex-column">
					<div class="h2 text-center">{{productSectionName}}</div>
					<div class="my-4">
						<label>Indtast din nummerplade for at finde reservedele til din bil</label>
						<ProductsNumberplate :product-section-url="productSectionUrl" />
					</div>
					<a :href="'/' + productSectionUrl" class="btn btn-block btn-link">
						Vælg mærke og model
						<i class="fa fa-angle-right fa-fw"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ProductsNumberplate from "@/components/Products/Numberplate.vue";
import CarsSearch from "@/components/Cars/Search.vue";

export default {
	props: ["productSectionUrl", "productSectionName"],
	components: {
		ProductsNumberplate,
		CarsSearch,
	},
};
</script>
