import Contact from "@/components/Products/Contact.vue";
import CategoryGroups from "@/components/Products/CategoryGroups.vue";
import VehicleSelector from "@/components/Products/VehicleSelector.vue";
import MakeModelSelector from "@/components/Products/MakeModelSelector.vue";
import Numberplate from "@/components/Products/Numberplate.vue";
import { componentRender } from "./utils";

componentRender(".products-contact", Contact);
componentRender(".products-category-groups", CategoryGroups);
componentRender(".products-vehicle-selector", VehicleSelector);
componentRender(".products-numberplate", Numberplate);
componentRender(".products-make-model-selector", MakeModelSelector);