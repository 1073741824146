import Vue from "vue";
import PortalVue from "portal-vue";
Vue.use(PortalVue);

import "core-js/stable";
import "regenerator-runtime/runtime";
import "./cars";
import "./products";
import "./garages";
import "./features";
import "./layout";
import "./tracking";