<template>
	<div class="filter">
		<form
			:class="`filter-form d-lg-flex ${mobileShow ? '' : 'd-none'}`"
			ref="form"
			:action="$root.action"
		>
			<div class="filter-heading">
				<div class="h3 mb-0">Filtre</div>
			</div>

			<FilterItem
				v-for="(filter, index) in $root.filters"
				:key="index"
				:label="filter.label"
				:id="filter.id"
				:data="filter.data"
				:type="filter.type"
				:searchable="filter.searchable"
				:single="filter.single"
				:disabled="filter.disabled"
				ref="filterItems"
			/>

			<div class="filter-buttons">
				<button
					type="button"
					class="filter-reset btn btn-link text-danger"
					v-show="isDirty"
					@click="reset()"
				>Nulstil</button>
				<button
					type="button"
					class="btn btn-link text-reset d-lg-none ml-auto"
					@click="mobileShow = false"
				>
					<i class="fas fa-times"></i>
				</button>
			</div>

			<div class="filter-mobile-close">
				<button
					type="button"
					class="btn btn-primary btn-block"
					@click="mobileShow = false"
				>Vis produkter ({{count}})</button>
			</div>
		</form>
		<button
			type="button"
			:class="`btn ${isDirty ? 'btn-primary' : 'btn-outline-secondary'} d-lg-none`"
			@click="mobileShow = !mobileShow"
		>Filtrér</button>
		<button
			type="button"
			class="btn btn-link text-danger d-lg-none"
			v-show="isDirty"
			@click="reset()"
		>Nulstil</button>
		<div class="filter-actives mt-2">
			<portal-target name="filter-actives" multiple />
		</div>
	</div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import FilterItem from "./FilterItem.vue";

const modelsCache = {};

export default {
	components: {
		FilterItem,
	},
	data() {
		return {
			view: null,
			mobileShow: false,
			count: 0,
		};
	},

	computed: {
		fields() {
			return this.$root.fields;
		},

		query() {
			return qs.stringify(Object.assign({}, this.fields, { sort: null }), { allowDots: true, arrayFormat: "repeat", skipNulls: true });
		},

		isDirty() {
			return this.query.length > 0;
		},
	},

	watch: {
		fields: {
			deep: true,
			handler(fields) {
				let url = this.$root.action;
				const search = this.query;
				if (search) {
					url += `?${search}`;
				}

				this.goToUrl(url);
			},
		}
	},

	methods: {
		reset() {
			this.$refs.filterItems.forEach(x => x.reset());
		},
		async goToUrl(url) {
			history.replaceState(null, "", url);
			const response = (await axios.get(url)).data;
			const doc = new DOMParser().parseFromString(response, "text/html");
			document.title = doc.title;
			document.querySelector("#heading").innerHTML = doc.querySelector("#heading").innerHTML;
			document.querySelector(".breadcrumbs").innerHTML = doc.querySelector(".breadcrumbs").innerHTML;
			document.querySelector(".list:not(body)").innerHTML = doc.querySelector(".list:not(body)").innerHTML;
			this.setCount();
		},
		setCount() {
			const countEl = document.querySelector("#list .top .count");
			if (countEl) {
				this.count = countEl.dataset.count;
			} else {
				this.count = 0;
			}
		},
	},

	beforeMount() {
		this.setCount();
	}
};
</script>