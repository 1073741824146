import { cleanObject, isInViewport } from "./utils";
// function gtag() {
// 	console.log(arguments);
// }

//track all outbound links
document.querySelectorAll("a[data-event-label]").forEach(el => {
	el.addEventListener("click", e => {
		const url = el.getAttribute("href");
		if (url.startsWith("http") || url.startsWith("//") || url[0] !== "/") {
			const label = el.dataset.eventLabel;
			send(label);
			if (el.target !== "_blank") {
				e.preventDefault();
				location = el.href;
			}
		}
	});

	function send(label) {
		let opts = {
			event_category: "outbound",
			event_label: label,
		};
		if (window.garage) {
			opts.garage = window.garage;
		}
		gtag("event", "click", opts);
	}
})

//List tracking
function trackLists() {
	document.body.querySelectorAll(".list").forEach(list => {
		if (!list.dataset.tracked && isInViewport(list)) {
			gtag("event", "view_item_list", {
				items: [...list.querySelectorAll("[track-item]")].map((el, i) => {
					const listItem = getTrackingItem(el, i);

					el.addEventListener("click", () => {
						gtag("event", "select_content", {
							content_type: "product",
							items: [listItem]
						})
					});

					return listItem;
				}),
			});
			list.dataset.tracked = true;
		}
	});
}

trackLists();

const observer = new MutationObserver((mutations) => {
	trackLists();
});

const list = document.body.querySelector(".list");
if (list) {
	observer.observe(document.body.querySelector(".list"), { childList: true });
}

document.addEventListener("scroll", trackLists, {
	passive: true
});
/***** */

if (document.body.classList.contains("details")) {
	const element = document.querySelector(".details[track-item]");
	if (element) {
		gtag("event", "view_item", {
			items: [getTrackingItem(element, 0)]
		});
	}
}

function getListName(el) {
	const bodyClassList = document.body.classList;
	const list = el.closest(".track-list");
	if (list && list.dataset.listName) {
		return list.dataset.listName;
	}

	if (bodyClassList.contains("list")) {
		let listName = "Listevisning ";
		if (bodyClassList.contains("cars"))
			listName += "biler";
		else if (bodyClassList.contains("products"))
			listName += "produkter";
		return listName;
	}

	if (bodyClassList.contains("home") && bodyClassList.contains("index")) {
		return "Forside";
	}

	if (!bodyClassList.contains("home") && bodyClassList.contains("index")) {
		if (bodyClassList.contains("cars"))
			return "Forside biler";
		else if (bodyClassList.contains("products"))
			return "Forside produkter";
	}

	if (bodyClassList.contains("details") && el.closest(".list")) {
		let listName = "Lignende ";
		if (bodyClassList.contains("cars"))
			listName += "biler";
		else if (bodyClassList.contains("products"))
			listName += "produkter";
		return listName
	}

	if (bodyClassList.contains("details") && el.closest("#details")) {
		let listName = "Detaljevisning ";
		if (bodyClassList.contains("cars"))
			listName += "biler";
		else if (bodyClassList.contains("products"))
			listName += "produkter";
		return listName
	}
}

function getTrackingItem(el, i) {
	return cleanObject({
		id: el.dataset.id,
		name: el.dataset.name,
		list_name: getListName(el),
		brand: el.dataset.brand,
		category: el.dataset.category,
		list_position: i + 1,
		variant: el.dataset.variant,
		price: el.dataset.price,
		seller: el.dataset.seller,
	});
}