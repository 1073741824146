<template>
	<form @submit.prevent="submit()">
		<div class="form-group">
			<div class="input-group">
				<select class="custom-select" v-model="make" @change="getModelGroups()">
					<option :value="null">Vælg mærke</option>
					<option v-for="make in makes" :value="make" :key="make.id">{{make.name}}</option>
				</select>
			</div>
		</div>

		<div class="form-group">
			<div class="input-group">
				<select
						class="custom-select"
						v-model="modelGroup"
						:disabled="!modelGroups.length"
						@change="getEngineSizes()">
					<option :value="null">Vælg model</option>
					<option
							v-for="modelGroup in modelGroups"
							:value="modelGroup"
							:key="modelGroup.name">{{modelGroup.name}}</option>
				</select>
			</div>
		</div>

		<div class="form-group" v-if="engineSizes.length">
			<div class="input-group">
				<select class="custom-select" v-model="engineSize" @change="getModelYears()">
					<option :value="null">Vælg motor</option>
					<option
							v-for="engineSize in engineSizes"
							:value="engineSize"
							:key="engineSize.name">{{engineSize.name}}</option>
				</select>
			</div>
		</div>

		<div class="form-group" v-if="modelYears.length">
			<div class="input-group">
				<select class="custom-select" v-model="modelYear" @change="getVehicles()">
					<option :value="null">Vælg årgang</option>
					<option
							v-for="modelYear in modelYears"
							:value="modelYear"
							:key="modelYear.year">{{modelYear.year}}</option>
				</select>
			</div>
		</div>

		<div v-if="vehicles.length">
			<select class="custom-select" v-model="vehicle">
				<option :value="null">Vælg variant</option>
				<option v-for="vehicle in vehicles" :value="vehicle" :key="vehicle.id">{{vehicle.name}}</option>
			</select>
		</div>
		<button class="btn btn-primary btn-block mt-3" type="submit" :disabled="!make || loading || (onlyVariantSearchBool && !vehicle)">
			<i class="fas fa-fw mr-2" :class="[loading ? 'fa-circle-notch fa-spin' : 'fa-search']"></i>
			Søg
		</button>
	</form>
</template>

<script>
import axios from "axios";

export default {
	props: [
		"makeId",
		"modelId",
		"vehicleId",
		"categoryId",
		"onlyVariantSearch",
		"productSectionUrl",
	],
	data() {
		return {
			make: null,
			makes: [],
			modelGroups: [],
			modelGroup: null,
			engineSize: null,
			engineSizes: [],
			modelYear: null,
			modelYears: [],
			vehicle: null,
			vehicles: [],
			loading: false,
		};
	},

	computed: {
		onlyVariantSearchBool() {
			return this.onlyVariantSearch
				? this.onlyVariantSearch.toLowerCase() === "true"
				: false;
		},
	},
	methods: {
		async getModelGroups() {
			this.modelGroup = null;
			this.modelGroups = [];
			this.engineSize = null;
			this.engineSizes = [];
			this.modelYear = null;
			this.modelYears = [];
			this.vehicle = null;
			this.vehicles = [];
			this.loading = true;
			if (this.make) {
				this.modelGroups = (
					await axios.get(
						`/api/products/makes/${this.make.id}/modelgroups?productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		async getEngineSizes() {
			this.engineSize = null;
			this.engineSizes = [];
			this.modelYear = null;
			this.modelYears = [];
			this.vehicle = null;
			this.vehicles = [];
			this.loading = true;
			if (this.modelGroup) {
				this.engineSizes = (
					await axios.get(
						`/api/products/makes/${this.make.id}/modelgroups/${this.modelGroup.name}/engineSizes?productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		async getModelYears() {
			this.modelYear = null;
			this.modelYears = [];
			this.vehicle = null;
			this.vehicles = [];
			this.loading = true;
			if (this.engineSize) {
				this.modelYears = (
					await axios.get(
						`/api/products/makes/${this.make.id}/modelgroups/${this.modelGroup.name}/engineSizes/${this.engineSize.name}/modelYears?productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		async getVehicles() {
			this.vehicle = null;
			this.vehicles = [];
			this.loading = true;
			if (this.modelYear) {
				this.vehicles = (
					await axios.get(
						`/api/products/makes/${this.make.id}/modelgroups/${this.modelGroup.name}/engineSizes/${this.engineSize.name}/modelYears/${this.modelYear.year}/vehicles?categoryId=${this.categoryId}&productSectionUrl=${this.productSectionUrl}`
					)
				).data;
			}
			this.loading = false;
		},
		submit() {
			let url = "";

			if (this.vehicle) {
				url = this.vehicle.url;
			} else if (this.modelYear) {
				url = this.modelYear.url;
			} else if (this.engineSize) {
				url = this.engineSize.url;
			} else if (this.modelGroup) {
				url = this.modelGroup.url;
			} else if (this.make) {
				url = this.make.url;
			}

			if (url) {
				window.location.href = url;
			}
		},
	},
	async created() {
		this.makes = (
			await axios.get(
				`/api/products/makes?productSectionUrl=${this.productSectionUrl}`
			)
		).data;

		if (this.makeId) {
			this.make = this.makes.find((x) => x.id == this.makeId);
			await this.getModelGroups();
		}

		if (this.modelId) {
			this.model = this.models.find((x) => x.id == this.modelId);
			await this.getVehicles();
		}
		if (this.vehicleId) {
			this.vehicle = this.vehicles.find((x) => x.id == this.vehicleId);
		}
	},
};
</script>