<template>
	<div class="products-numberplate">
		<form @submit.prevent="getVehicle()">
			<div class="form-row" :class="{ 'is-invalid': notFound }">
				<div class="col">
					<div class="numberplate">
						<input
							   type="text"
							   class="form-control form-control-lg"
							   v-model="numberplate"
							   required
							   placeholder="AB 123 45"
							   name="numberplate" />
					</div>
				</div>
				<div class="col-auto">
					<button type="submit" class="btn btn-primary btn-lg h-100 px-5" :disabled="!numberplate">
						<i class="fas fa-fw mr-2" :class="[loading ? 'fa-circle-notch fa-spin' : 'fa-search']"></i>
						Søg
					</button>
				</div>
			</div>
			<div
				 class="invalid-feedback">Vi kan ikke finde din bil i øjeblikket, vælg din bil fra listen i stedet.</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";

export default {
	props: ["categoryId", "productSectionUrl"],
	data() {
		return {
			numberplate: null,
			notFound: false,
			loading: false,
		};
	},

	computed: {},
	methods: {
		async getVehicle() {
			this.notFound = false;
			this.loading = true;

			let vehicle = (
				await axios.get(
					`/api/products/numberplate/${this.numberplate}/vehicle?productSectionUrl=${this.productSectionUrl}&categoryId=${this.categoryId}`
				)
			).data;

			if (vehicle && vehicle.url) {
				window.location.href = vehicle.url;
			} else {
				this.loading = false;
				this.notFound = true;
			}
		},
	},
};
</script>