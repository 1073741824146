import Filter from "@/components/Filter/Filter.vue"
import Vue from "vue";
import axios from "axios";
import { componentRender } from "./utils";
import CarsSearch from "@/components/Cars/Search.vue";
import Contact from "@/components/Cars/Contact.vue";

if (document.querySelector(".cars-filter")) {
	(async () => {
		const modelsCache = {};
		const models = {};
		const [makes, fuelTypes, bodyTypes] = await Promise.all([
			axios.get("/api/cars/makes"),
			axios.get("/api/cars/fueltypes"),
			axios.get("/api/cars/bodytypes"),
		]);

		const activeMake = makes.data.find(x => location.pathname.indexOf(x.url) > -1);
		let activeModel;
		if (activeMake) {
			models[activeMake.name] = await getModels(activeMake.id);
			activeModel = models[activeMake.name].find(x => location.pathname.indexOf(x.url) > -1);
		}

		const filters = [
			{
				id: "makes",
				label: "Mærke",
				data: makes.data,
				type: "List",
				searchable: true,
			},
			{
				id: "models",
				label: "Model",
				data: models,
				type: "GroupList",
				searchable: true,
			},
			{
				id: "year",
				label: "Modelår",
				data: years,
				type: "Range",
			},
			{
				id: "price",
				label: "Pris",
				data: prices,
				type: "Range",
			},
			{
				id: "mileage",
				label: "Kilometer",
				data: mileages,
				type: "Range",
			},
			{
				id: "fuelTypes",
				label: "Brændstof",
				data: fuelTypes.data,
				type: "List",
			},
			{
				id: "bodyTypes",
				label: "Biltype",
				data: bodyTypes.data,
				type: "List",
				single: true,
			},
			{
				id: "q",
				label: "Fritekst",
				type: "Text",
			},
		];

		new Vue({
			render: x => x(Filter),
			data() {
				const params = new URLSearchParams(location.search);
				return {
					filters: filters,
					fields: {
						makes: params.getAll("makes").map(x => parseInt(x)),
						models: params.getAll("models").map(x => parseInt(x)),
						year: { min: parseInt(params.get("year.min")) || null, max: parseInt(params.get("year.max")) || null },
						price: { min: parseInt(params.get("price.min")) || null, max: parseInt(params.get("price.max")) || null },
						mileage: { min: parseInt(params.get("mileage.min")) || null, max: parseInt(params.get("mileage.max")) || null },
						fuelTypes: params.getAll("fuelTypes").filter(x => x).map(x => parseInt(x)),
						bodyTypes: params.getAll("bodyTypes").filter(x => x).map(x => parseInt(x)),
						q: params.get("q"),
						sort: params.get("sort"),
					},
				}
			},
			computed: {
				action() {
					if (this.fields.makes.length === 1 && this.fields.models.length === 0) {
						return makes.data.find(x => x.id == this.fields.makes[0]).url
					} else if (this.fields.makes.length === 1 && this.fields.models.length === 1) {
						return Object.values(models)[0].find(x => x.id == this.fields.models[0]).url;
					}
					return "/biler-til-salg";
				},
			},
			watch: {
				"fields.makes": {
					immediate: true,
					handler: async function (activeMakes, oldActiveMakes) {
						const removedMakes = (oldActiveMakes || []).filter(x => !activeMakes.includes(x));
						for (const removed of makes.data.filter(x => removedMakes.includes(x.id))) {
							models[removed.name].forEach(model => {
								const index = this.fields.models.findIndex(x => x === model.id);
								if(index > -1) {
									this.fields.models.splice(index, 1);
								}
							});
							this.$delete(models, removed.name)
						}
						for (const make of makes.data.filter(x => activeMakes.includes(x.id))) {
							this.$set(models, make.name, await getModels(make.id));
						}
					}
				}
			},
			created() {
				if (activeMake) {
					if (!this.fields.makes.includes(activeMake.id)) {
						this.fields.makes.push(activeMake.id);
					}
				}
				if (activeModel) {
					if (!this.fields.models.includes(activeModel.id)) {
						this.fields.models.push(activeModel.id);
					}
				}
			}
		}).$mount(".cars-filter");

		async function getModels(makeId) {
			const models = modelsCache[makeId] || (await axios.get(`/api/cars/makes/${makeId}/models`)).data;
			modelsCache[makeId] = models;
			return models;
		}
	})();
}

const carList = document.querySelector(".list");
if (carList) {
	carList.querySelectorAll(".thumbnail").forEach(thumbnail => {
		thumbnail.addEventListener("mouseenter", e => {
			e.target.closest(".list-item").querySelector(".image > div").style.backgroundImage = `url('${e.target.dataset.src}')`;
		})
	});
}

export const prices = {
	ranges: [1, 10000, 25000, 50000, 75000, 100000, 125000, 150000, 200000, 250000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000],
	format: true,
	unit: "kr.",
};
export const mileages = {
	ranges:  [10000, 25000, 50000, 75000, 100000, 125000, 150000, 200000, 250000, 300000, 400000, 500000],
	format: true,
	unit: "km.",
};
export const years = {
	ranges: [],
};
for (let year = 1980; year <= new Date().getFullYear(); year++) {
	years.ranges.push(year);
}

componentRender(".cars-search", CarsSearch);
componentRender(".cars-contact", Contact);

// if(document.body.classList.contains("cars") && document.body.classList.contains("index")) {
// 	setInterval(async () => {
// 		const result = (await axios.get("/api/cars/count")).data;
// 		const elem = document.querySelector(".total-count");
// 		if(elem.countup) {
// 			elem.countup.update(result.totalCount);
// 		}
// 	}, 10000);
// }
