<template>
	<div class="filter-range" ref="filter">
		<div class="form-row">
			<div class="col-6">
				<select
					:name="`${id}.min`"
					class="custom-select"
					v-model="active.min"
					@change="minValueChanger()"
				>
					<option :value="null">Fra</option>
					<option
						:value="range"
						v-for="(range, index) in data.ranges"
						:key="index"
					>{{value(range)}}</option>
				</select>
			</div>
			<div class="col-6">
				<select :name="`${id}.max`" class="custom-select" v-model="active.max">
					<option :value="null">Til</option>
					<option
						:value="range"
						v-for="(range, index) in minMaxValues"
						:key="index"
					>{{value(range)}}</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["data", "id"],
	data() {
		return {
			tempValue: { min: null, max: null, },
		}
	},
	computed: {
		minMaxValues() {
			return this.data.ranges.filter(x => x > this.active.min);
		},

		active() {
			return this.$root.fields ? this.$root.fields[this.id] : this.tempValue;
		},
		
		info() {
			return `${this.value(this.active.min)} - ${this.value(this.active.max)}`
		}
	},
	methods: {
		minValueChanger() {
			if (this.active.min >= this.active.max) {
				this.active.max = null;
			}
		},
		value(value) {
			return (this.data.format ? value.toLocaleString("da-DK") : value) + (this.data.unit ? ` ${this.data.unit}` : "");
		},
	},
}
</script>