import Vue from "vue";
export function componentRender(selector, component, props) {
	let element;
	if (typeof selector === "string") {
		element = document.querySelector(selector);;
	} else {
		element = selector;
	}
	if (element) {
		if (!props) {
			props = element.dataset;
		}

		new Vue({
			render(createElement) {
				return createElement(component, {
					props: props
				})
			}
		}).$mount(element);
	}
}

export const googleMapsMixin = {
	data() {
		return {
			map: null,
		}
	},

	mounted() {
		const mapsScript = document.createElement("script");
		mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${s4c.apiKeys.googleMapsFrontend}`;
		mapsScript.id = s4c.apiKeys.googleMapsFrontend;
		mapsScript.async = true;
		mapsScript.defer = true;
		document.head.appendChild(mapsScript);

		mapsScript.onload = this.renderMap;
	},
}

export function cleanObject(obj) {
	for (const key in obj) {
		if (obj[key] === null || obj[key] === undefined) {
			delete obj[key];
		}
	}
	return obj;
}

export function isInViewport(element) {
	const bounding = element.getBoundingClientRect();
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom - bounding.height <= (window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right - bounding.width <= (window.innerWidth || document.documentElement.clientWidth)
	);
};